
<template>
<div>
    <div class="market-profile-inner">
        <ul class="m-pointer" style="margin-bottom: 0;">
            <li class="li-head">
                <div class="profile-inner-table-row">
                    <div class="profile-inner-table-name">تاریخچه</div>
                    <div class="profile-inner-table-value">تاریخ</div>
                    <div class="profile-inner-table-actions actions-min-w" style="min-width: 18px;"></div>
                </div>
            </li>

            <li v-for="(item, index) in stock_history"  v-if="stock_history" :key="index" :class="activeLi == index+10 ? 'active-current' : ''">
                <div class="profile-inner-table-row" @click="setActive(index+10)">
                    <div class="profile-inner-table-name">تاریخ</div>
                    <div class="profile-inner-table-value">{{ item.last_date }}</div>
                    <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                </div>

                <div class="profile-inner-table-box with-border">
                    <div class="profile-inner-table-item">
                        <div class="profile-inner-table-row">
                            <div class="profile-inner-table-name">تغییر</div>
                            <div class="profile-inner-table-value min-f-w">
                                تعداد: <span class="num-w">{{ item.ZTotTran }}</span><br>
                                حجم: <span class="num-w">{{ item.QTotTran5J }}</span><br>
                                ارزش: <span class="num-w">{{ item.QTotCap }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="profile-inner-table-item">
                        <div class="profile-inner-table-row">
                            <div class="profile-inner-table-name">کمترین</div>
                            <div class="profile-inner-table-value min-f-w">
                                اولین: <span class="num-w">{{ item.PriceFirst }}</span><br>
                                کمترین: <span class="num-w">{{ item.PriceMin }}</span><br>
                                بیشترین: <span class="num-w">{{ item.PriceMax }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="profile-inner-table-item">
                        <div class="profile-inner-table-row">
                            <div class="profile-inner-table-name">بیشترین</div>
                            <div class="profile-inner-table-value min-f-w">
                                قیمت: <span class="num-w">{{ item.PDrCotVal }}</span><br>
                                تغییر: <span class="num-w">{{ item.PriceChange }}</span><br>
                                درصد تغییر: <span class="num-w">{{ item.last_change_percentage }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="profile-inner-table-item">
                        <div class="profile-inner-table-row">
                            <div class="profile-inner-table-name">زمان</div>
                            <div class="profile-inner-table-value min-f-w">
                                قیمت: <span class="num-w">{{ item.PClosing }}</span><br>
                                تغییر: <span class="num-w">{{ item.closing_change }}</span><br>
                                درصد تغییر: <span class="num-w">{{ item.closing_change_percentage }}</span>
                            </div>
                        </div>
                    </div> 
                </div>
            </li>

        </ul>
    </div>        
</div>
</template>

<script>
export default {
    name: 'StockHistory',
    data() {
        return {
            stock_history: null,
            widgetLoad: true,
            activeLi: 100,
        }
    },
    mounted() {
        this.getProfileHistory();
    },
    methods: {
        getProfileHistory() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-history/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_history = api_response.data.response.history.body.response.items;
                        this.widgetLoad = false;
                        // this.stock_header_summary = api_response.data.response.detail.response.summary;
                        // $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        // $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>